<template>
<div class="d-flex flex-column justify-content-start align-items-center">
	<h3 class="font-weight-bold">{{$t('donationView.title')}}</h3>
	<p class="mb-3 text-center">{{$t('donationView.description')}}</p>

	<h4 class="font-weight-bold">{{$t('donationView.method')}}</h4>
	<SelectButton class="mt-2 mx-auto" v-model="selectedMethod" :options="methods" :option-label="(item) => $t(item.label)" :option-value="(item) => item.value" data-key="value" aria-labelledby="basic" />

	<Divider class="mt-2"/>

	<div v-if="selectedMethod === 'SIMPLEPAY'" class="mt-2 d-flex flex-column align-items-centermx-auto" style="width: fit-content;">
		<div class="d-flex flex-row flex-wrap">
			<ToggleButton style="flex-basis: 50%;" v-model="selectedAmountChooser[0].value" :on-label="selectedAmountChooser[0].label + ' Ft'" :off-label="selectedAmountChooser[0].label + ' Ft'" @change="changeAmount(0)"/>
			<ToggleButton style="flex-basis: 50%;" v-model="selectedAmountChooser[1].value" :on-label="selectedAmountChooser[1].label + ' Ft'" :off-label="selectedAmountChooser[1].label + ' Ft'" @change="changeAmount(1)"/>
			<ToggleButton style="flex-basis: 50%;" v-model="selectedAmountChooser[2].value" :on-label="selectedAmountChooser[2].label + ' Ft'" :off-label="selectedAmountChooser[2].label + ' Ft'" @change="changeAmount(2)"/>
			<ToggleButton style="flex-basis: 50%;" v-model="selectedAmountChooser[3].value" :on-label="$t(selectedAmountChooser[3].label)" :off-label="$t(selectedAmountChooser[3].label)" @change="changeAmount(3)"/>
		</div>
		<div class="d-flex flex-column">
			<InputNumber v-if="selectedAmountChooser[3].value" class="mt-2" v-model="selectedAmount" currency="HUF" mode="currency" :min="100" :min-fraction-digits="0" :max-fraction-digits="0" :placeholder="$t('donationView.other')"/>
			<label for="nameInput" class="mb-0 mt-2">{{$t('donationView.name')}}</label>
			<InputText id="nameInput" v-model="name" :placeholder="$t('donationView.name')"/>
			<label for="emailInput" class="mb-0 mt-2">{{$t('donationView.email')}}</label>
			<InputText id="emailInput" v-model="email" :placeholder="$t('donationView.email')"/>
		</div>
		<div class="d-flex align-items-center gap-2 mt-2" style="max-width: 450px">
			<Checkbox id="acceptTerms" v-model="accepted" binary/>
			<label for="acceptTerms">
				{{$t('donationView.acceptTerms1')}}
				<a href="#/gtc-donation" target="_blank">{{$t('donationView.acceptTerms_link1')}}</a>
				{{$t('donationView.acceptTerms2')}}
				<a :href="privacyStatementLink" target="_blank">{{$t('donationView.acceptTerms_link2')}}</a>{{$t('donationView.acceptTerms3')}}</label>
		</div>
		<Button class="mt-2 w-auto align-self-center" @click="donate" severity="primary" :disabled="disabled || loading">
			<font-awesome-icon :icon="loading ? 'spinner' : 'money-bill'" class="mr-2" :class="{'spinning-animation': loading}" />
			{{$t('donationView.donate')}}
		</Button>
	</div>

	<div v-else-if="selectedMethod === 'TRANSFER'" class="mt-2 text-center">
		<p class="mb-2">{{$t('donationView.transferDescription')}}</p>
		<p class="mb-0"><b>{{$t("donationView.accountHolder")}}: </b> PilotNet Kft.</p>
		<p class="mb-0"><b>{{$t('donationView.accountNumber')}}: </b> 11742049-21467932</p>
		<p class="mb-0" v-if="$i18n.locale === 'en'"><b>{{$t('donationView.iban')}}: </b> HU22117420492146793200000000</p>
		<p class="mb-0" v-if="$i18n.locale === 'en'"><b>{{$t('donationView.swiftCode')}}: </b> OTPVHUHB</p>
		<p class="mb-0"><b>{{$t('donationView.bank')}}: </b> OTP Bank (Magyarország, 1051 Budapest, Nádor utca 16.)</p>
	</div>

	<Dialog position="top" :header="$t('donationView.info')" :visible.sync="visibleModal">
		<p style="white-space: pre-wrap">{{modalContent}}</p>
		<template #footer>
			<Button @click="visibleModal=false">
				{{$t('donationView.ok')}}
			</Button>
		</template>
	</Dialog>
</div>
</template>

<script>
import SelectButton from 'primevue/selectbutton';
import Divider from 'primevue/divider';
import Button from 'primevue/button';
import ToggleButton from 'primevue/togglebutton';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Checkbox from "primevue/checkbox";
import Dialog from 'primevue/dialog';
import {store} from '@/store/store';
import i18n from '@/i18n';

export default {
	name: "DonationView",
	components: {
		SelectButton,
		Divider,
		Button,
		ToggleButton,
		InputNumber,
		InputText,
		Checkbox,
		Dialog,
	},
	data() {
		return {
			selectedMethod: "SIMPLEPAY",
			methods: [
				{label: 'donationView.simplePay', value: "SIMPLEPAY"},
				{label: 'donationView.transfer', value: "TRANSFER"}
			],
			selectedAmountChooser:[
				{label: '1 000', id: 1000, value: true},
				{label: '5 000', id: 5000, value: false},
				{label: '10 000', id: 10000, value: false},
				{label: 'donationView.other', id: 'OTHER', value: false}
			],
			selectedAmount: 1000,
			email: store.getters.loggedInEmail ? store.getters.loggedInEmail : "",
			name: store.getters.getLoggedInUserData && store.getters.getLoggedInUserData.name ? store.getters.getLoggedInUserData.name : "",
			accepted: false,
			visibleModal: false,
			modalContent: "",
			loading: false
		}
	},
	computed: {
		disabled() {
			return !this.accepted || !this.email || !this.name || !this.selectedAmount
		},
		privacyStatementLink() {
			return '#/privacy-statement' + (i18n.locale === 'hu' ? '-hu' : '')
		}
	},
	methods: {
		changeAmount(id) {
			this.selectedAmountChooser.forEach((item, index) => {
				if(index === id) {
					item.value = true
					if(item.id === 'OTHER') {
						this.selectedAmount = 1000
					}
					else {
						this.selectedAmount = item.id
					}
				}
				else {
					item.value = false
				}
			})
		},
		async donate() {
			if(this.disabled || this.loading) return
			this.loading = true

			let result = await this.$rest.donate(this.selectedAmount, this.email, this.name)
			if(result.data.result) {
				window.location.href = result.data.data
			}
			this.loading = false
		},
	},
	async mounted() {
		if(!this.$route.query.result) return
		if(this.$route.query.result === "success" && this.$route.query.transaction) {
			this.modalContent = this.$t('donationView.success') + this.$route.query.transaction
		}
		else if(this.$route.query.result === "cancel") {
			this.modalContent = this.$t('donationView.cancel')
		}
		else if(this.$route.query.result === "fail" && this.$route.query.transaction) {
			this.modalContent = this.$t('donationView.fail1') + this.$route.query.transaction + this.$t('donationView.fail2')
		}
		this.visibleModal = true

		await this.$router.replace({'query': {}})
	}
}
</script>

<style scoped>
.p-dialog-mask.p-dialog-top {
	z-index:9999 !important;
}

.spinning-animation {
	-webkit-animation-name: spin;
	-webkit-animation-duration: 4000ms;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	-moz-animation-name: spin;
	-moz-animation-duration: 4000ms;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;
	-ms-animation-name: spin;
	-ms-animation-duration: 4000ms;
	-ms-animation-iteration-count: infinite;
	-ms-animation-timing-function: linear;

	animation-name: spin;
	animation-duration: 4000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
@-ms-keyframes spin {
	from { -ms-transform: rotate(0deg); }
	to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
	from { -moz-transform: rotate(0deg); }
	to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
	from {
		transform:rotate(0deg);
	}
	to {
		transform:rotate(360deg);
	}
}
</style>
